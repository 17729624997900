import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/flowable/model"

export default {
	...commonApi(moduleName),
	apiGetPage: data =>
		request("/flowable/models", {
			method: "get",
			params: data
		}),
	apiGetFormList: data =>
		request(`/flowable/model/${data.id}/forms`, {
			method: "get"
		}),
	apiGetRoleList: () =>
		request("/auth/role/fetch", {
			method: "get"
		}),
	apiFormListOrder: data =>
		request(`/flowable/model/${data.id}/form-sort`, {
			method: "put",
			body: data
		}),
	apiCopyProcess: data =>
		request(`/flowable/model/${data.modelId}/copy`, {
			method: "post",
			body: data
		}),
	apiDisableProcess: data =>
		request(`/flowable/model/disable/${data.modelId}`, {
			method: "delete"
		}),
	apiSortProcess: data =>
		request("/flowable/model/model-sort", {
			method: "put",
			body: data
		}),
	// 查询所有流程流程设计类型
	apiTypeProcess: () =>
		request("/flowable/process-notify-types", {
			method: "get"
		})
}
