<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="initList" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="文件名称">
				<Input placeholder="请输入文件名称" v-model="searchParams.name" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="创建时间" placement="top">
				<DatePicker
					placeholder="请选择创建时间"
					format="yyyy-MM--DD"
					type="daterange"
					:transfer="true"
					:clearable="true"
					v-model="createdTime"
				/>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button type="primary" @click="showFlowList">上传文件</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="false"
		></CtmsDataGrid>
		<Modal
			title="流程列表"
			:value="visible"
			width="1000"
			@on-cancel="visible = false"
			:mask-closable="false"
		>
			<CtmsDataGrid
				:columns="flowColumns"
				:data="flowList"
				:page="false"
				:height="dataGridHeight"
			>
				<template slot-scope="{ row, index }" slot="action">
					<Upload
						:before-upload="file => getMD5(file, row, index)"
						:show-upload-list="false"
						:accept="filetype"
						action="/"
					>
						<Button type="primary" :loading="row.loading">
							{{ row.loading ? "上传中" : "上传文件" }}
						</Button>
					</Upload>
				</template>
			</CtmsDataGrid>
			<div slot="footer">
				<Button
					style="margin-right: 8px"
					type="primary"
					@click="visible = false"
					>关闭
				</Button>
			</div>
		</Modal>
		<!--  pdf 预览-->
		<formModulePreview
			:visible="previewVisible"
			:isFullScreen="true"
			:documentSrc="documentSrc"
			:id="`preView${selId}`"
			@onCancel="previewVisible = false"
		></formModulePreview>
		<Upload
			ref="updateFile"
			style="display: none"
			:before-upload="file => getUpdateMD5(file, updateRow)"
			:show-upload-list="false"
			:accept="filetype"
			action="/"
		>
		</Upload>
	</div>
</template>

<script>
import api from "@/api/project/formTemplate"
import processApi from "@/api/setting/process"

import moment from "moment"
import { mapState } from "vuex"
import { formDataToMD5 } from "@/utils/util"
import formModulePreview from "@/components/BusinessComponents/Document/DocumentPreview.vue"

const { getList, uploadFile, disabledFile, uploadUpdateFile } = api
const { apiGetPage } = processApi
export default {
	name: "setFormTemplate",
	data() {
		return {
			loading: false,
			uploading: false,
			selId: "",
			documentSrc: "",
			previewVisible: false,
			columns: [
				{
					title: "文件名",
					key: "name"
				},
				{
					title: "创建时间",
					key: "createdTime"
				},
				{
					title: "流程名称",
					key: "flowName"
				},
				{
					title: "操作",
					key: "action",
					width: 200,
					fixed: "right",
					renderButton: () => {
						const actionList = [
							{
								label: "预览",
								on: {
									click: this.previewTemplate
								}
							},
							{
								label: "下载",
								on: {
									click: this.downloadTemplate
								}
							},
							{
								label: "更新",
								on: {
									click: this.updateTemplate
								}
							},
							{
								label: "删除",
								on: {
									click: this.onDeleteItem
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							}
						]
						return actionList
					}
				}
			],
			listData: [],
			searchParams: {
				name: ""
			},
			oldSearchParams: {},
			createdTime: [],
			filetype: "docx",
			visible: false,
			flowList: [],
			flowColumns: [
				{
					title: "流程名称",
					key: "name",
					minWidth: 200
				},
				{
					title: "流程图分类",
					key: "category.lable",
					render: (h, params) => {
						const txt = this.processList.find(
							o => o.obj.nickname === params.row.category
						).name
						return h("span", {}, txt)
					},
					minWidth: 200
				},
				{
					title: "描述",
					key: "description",
					minWidth: 200
				},
				{
					title: "操作",
					slot: "action",
					width: 100
				}
			],
			updateRow: {}
		}
	},
	computed: {
		...mapState("project", ["infoHeight"]),
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 146
			}
		}),
		...mapState("permission", ["projectActionPermissions"]),
		...mapState("user", ["processList"])
	},
	components: {
		formModulePreview
	},
	methods: {
		// 重置
		reset() {
			this.searchParams = {
				name: ""
			}
			this.createdTime = []
			this.initList()
		},
		async initList() {
			this.loading = true
			const startDate =
				(this.createdTime[0] &&
					moment(this.createdTime[0]).format("YYYY-MM-DD")) ||
				""
			const endDate =
				(this.createdTime[1] &&
					moment(this.createdTime[1]).format("YYYY-MM-DD")) ||
				""
			const res = await getList({
				projectId: this.$route.params.projectId,
				...this.searchParams,
				startDate,
				endDate
			})
			if (res) {
				this.listData = res.data
					.filter(item => item.isDel === 0)
					.map(item => ({
						...item,
						loading: false
					}))
			}
			this.loading = false
		},
		async showFlowList() {
			this.visible = true
			const res = await apiGetPage({
				projectId: this.$route.params.projectId,
				pageNum: 1,
				pageSize: 999,
				isDisable: 0
			})
			if (res) {
				this.flowList = res.data
			}
		},
		// md5
		getMD5(file, row, index) {
			if (row.loading) {
				return false
			}

			this.$set(this.flowList, index, {
				...row,
				loading: true
			})
			const suffixs = file.name.split(".")
			if (!suffixs.includes(this.filetype)) {
				this.$Message.error("只允许上传格式为docx！")
				this.$set(this.flowList, index, {
					...row,
					loading: false
				})
				return false
			}

			// if (file.size > 1024 * 1024 * 10) {
			//   this.$Message.error('文件大小不能超过10M！');
			//   return false;
			// }
			formDataToMD5(file, md5Str => {
				this.onUploadFile(file, row, index, md5Str)
			})
			return false
		},
		// 上传文件
		onUploadFile(file, row, index, md5) {
			const fd = new FormData()
			fd.append("file", file)
			const params = {
				md5,
				projectId: this.$route.params.projectId,
				flowId: row.id
			}
			uploadFile(fd, params).then(res => {
				if (res) {
					this.$Message.success("上传成功!")
					this.initList()
				}
				this.$set(this.flowList, index, {
					...row,
					loading: false
				})
			})
		},
		// 更新文件获取md5
		getUpdateMD5(file, row) {
			const suffixs = file.name.split(".")
			if (!suffixs.includes(this.filetype)) {
				this.$Message.error("只允许上传格式为docx！")
				return false
			}
			formDataToMD5(file, md5Str => {
				this.onUpdateUploadFile(file, row, md5Str)
			})
			return false
		},
		// 上传更新文件
		onUpdateUploadFile(file, row, md5) {
			this.loading = true
			const fd = new FormData()
			fd.append("file", file)
			const params = {
				md5,
				id: row.id
			}
			uploadUpdateFile(fd, params).then(res => {
				if (res) {
					this.$Message.success("上传成功!")
					this.initList()
				}
				this.loading = false
			})
		},
		onDeleteItem({ row }) {
			disabledFile(row.id).then(res => {
				if (res) {
					this.$Message.success("删除成功！")
					this.initList()
				} else {
					this.$Message.error("删除成功！")
				}
			})
		},
		downloadTemplate({ row }) {
			window.open(
				`${this.$baseUrl}/document/attachment/download/${row.id}?token=${this.$store.state.user.token}`
			)
		},
		// 预览
		previewTemplate({ row }) {
			this.selId = row.id
			this.documentSrc = `${this.$baseUrl}/document/attachment/inline/${row.id}?token=${this.$store.state.user.token}&isTransfer=1`
			this.previewVisible = true
		},
		// 更新文件
		updateTemplate({ row }) {
			this.updateRow = row
			this.$refs.updateFile.handleClick()
		}
	},
	mounted() {
		this.initList()
	}
}
</script>

<style scoped></style>
